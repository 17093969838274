import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Full Snatch 4-4-4-4 to a 4RM`}</p>
    <p>{`then,`}</p>
    <p>{`10:00 EMOM of:`}</p>
    <p>{`1-Power Snatch`}</p>
    <p>{`1-Hang Squat Snatch`}</p>
    <p>{`1-OHS`}</p>
    <p>{`*`}{`using 80% of 1RM of your weakest movement`}</p>
    <p>{`then at minute 12:00,`}</p>
    <p>{`3 Rounds for time of:`}</p>
    <p>{`30-OH Walking Lunge Steps (45/25`}{`#`}{`)`}</p>
    <p>{`20-Power Snatch (75/55)`}</p>
    <p>{`10-HSPU’s`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Starting Monday, December 4th all mid morning classes will be at
10:30am.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      